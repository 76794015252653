import { omit, sortBy } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import {
  Alt,
  BoxSeam,
  Cart,
  Check,
  Check2,
  ChevronRight,
  ExclamationTriangleFill,
  Pencil,
  PlusCircleDotted,
  Sliders,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { ProductLevelAttributeModal } from '../../components/ProductLevelAttribute'
import { ProductLevelModal } from '../../components/ProductLevelModal'
import { ProductLevelOptionModal } from '../../components/ProductLevelOptionModal'
import { useProductLevelAttributes } from '../../hooks/useProductLevelAttributes'
import { useProductLevelOptions } from '../../hooks/useProductLevelOptions'
import { useProductLevel } from '../../hooks/useProductLevels'

export function ProductLevel() {
  const { level: id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const attributesFilter = useMemo(
    () => ({
      product_level: id,
    }),
    [id]
  )

  const [{ level, error }, { update, destroy }] = useProductLevel(id)
  const [
    { attributes, loading: attributesLoading, error: attributesError },
    { create: createAttribute, update: updateAttribute, destroy: removeAttribute },
  ] = useProductLevelAttributes(attributesFilter)

  const [
    { options, loading: optionsLoading, error: optionsError },
    { create: createOption, destroy: removeOption, update: updateOption },
  ] = useProductLevelOptions(attributesFilter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()
  const [optDelModalState, optDelModalActions] = useModalTrigger()
  const [attrDelModalState, attrDelModalActions] = useModalTrigger()
  const [createAttributeModalState, createAttributeModalActions] = useModalTrigger()
  const [createOptionModalState, createOptionModalActions] = useModalTrigger()

  if (error || attributesError || optionsError) {
    return <PageError error={error || attributesError} />
  }

  if (level === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <h2 className="m-0">
              <Link className="d-flex aling-items-center" to={`/catalog/${level.product}`}>
                <Cart className="mr-2" size={31} />
                {level.product_name}
              </Link>
            </h2>
            <ChevronRight className="ml-3" size={20} />
            <h2 className="m-0 ml-3 d-flex align-items-center">
              <BoxSeam size={31} className="mr-2" />
              {level.name}
            </h2>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(level)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_product_level')}
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => delModalActions.open(level)}
              icon={<Trash size={15} />}
            >
              {t('delete_product_level')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('id')} value={level.id} />
            <Detail.Row label={t('code')} value={level.code} />
            <Detail.Row label={t('description')} value={level.description} />
            <Detail.Row label={t('legacy')} value={level.legacy_mode ? <Check /> : null} />
            <Detail.Row label={t('deprecated')} value={level.deprecated ? <ExclamationTriangleFill /> : null} />
          </Detail>
        </div>
        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Sliders className="mr-2" /> {t('available_attributes')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createAttributeModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_available_attribute')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table loading={attributesLoading} hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>{t('name')}</th>
                <th style={{ width: '18%' }}>{t('code')}</th>
                <th style={{ width: '27%' }}>{t('description')}</th>
                <th style={{ width: '20%' }}></th>
              </tr>
            </thead>
            <tbody>
              {attributes &&
                attributes.map((attr) => (
                  <tr key={attr.id}>
                    <td>{attr.name}</td>
                    <td style={{ lineBreak: 'anywhere' }}>{attr.code}</td>
                    <td>{attr.description}</td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => createAttributeModalActions.open(attr)}
                        icon={<Pencil size={15} />}
                        className="mr-2"
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => attrDelModalActions.open(attr)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="border-bottom mt-4 pb-2 d-flex justify-content-between align-items-end">
          <h3 className="m-0 d-flex align-items-center">
            <Alt className="mr-2" /> {t('available_options')}
          </h3>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => createOptionModalActions.open({})}
            icon={<PlusCircleDotted size={15} />}
            className="mr-2"
          >
            {t('create_available_option')}
          </Button>
        </div>
        <div className="mt-4 px-3">
          <Table loading={optionsLoading} hover responsive className="table-layout-fixed">
            <thead>
              <tr>
                <th style={{ width: '13%' }}>{t('name')}</th>
                <th style={{ width: '15%' }}>{level.legacy_mode ? t('bit') : t('code')}</th>
                <th style={{ width: '10%' }} class="text-center">
                  {t('default')}
                </th>
                <th style={{ width: '22%' }}>{t('notes')}</th>
                <th style={{ width: '20%' }}></th>
              </tr>
            </thead>
            <tbody>
              {options &&
                sortBy(options, 'bit').map((opt) => (
                  <tr key={opt.id}>
                    <td>{opt.option_name}</td>
                    <td style={{ lineBreak: 'anywhere' }}>{level.legacy_mode ? opt.bit : opt.option_code}</td>
                    <td class="text-center">{opt.enabled_by_default && <Check2 />}</td>
                    <td>{opt.notes}</td>
                    <td className="text-right">
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => createOptionModalActions.open(opt)}
                        icon={<Pencil size={15} />}
                        className="mr-2"
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => optDelModalActions.open(opt)}
                        icon={<Trash size={15} />}
                      >
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <ModalConfirmDelete
        onDelete={() => {
          const product = level.product
          destroy
            .onSuccess(() => {
              delModalActions.close()
              history.replace(`/catalog/${product}`)
            })
            .run(level.id)
        }}
        isOpen={delModalState.isOpen}
        toggle={delModalActions.toggle}
        onExited={delModalActions.onClosed}
        title={t('delete_product_level_title', {
          name: level.name,
        })}
        text={t('delete_product_level_text', {
          name: level.name,
        })}
      />
      {optDelModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            removeOption(optDelModalState.value.id)
            optDelModalActions.close()
          }}
          isOpen={optDelModalState.isOpen}
          toggle={optDelModalActions.toggle}
          onExited={optDelModalActions.onClosed}
          title={t('delete_product_level_option_title', {
            name: optDelModalState.value.option_name,
            level: level.name,
          })}
          text={t('delete_product_level_option_text', {
            name: optDelModalState.value.option_name,
            level: level.name,
          })}
        />
      )}
      {attrDelModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            removeAttribute(attrDelModalState.value.id)
            attrDelModalActions.close()
          }}
          isOpen={attrDelModalState.isOpen}
          toggle={attrDelModalActions.toggle}
          onExited={attrDelModalActions.onClosed}
          title={t('delete_product_level_attribute_title', {
            name: attrDelModalState.value.name,
            level: level.name,
          })}
          text={t('delete_product_level_attribute_text', {
            name: attrDelModalState.value.name,
            level: level.name,
          })}
        />
      )}
      {editModalState.value && (
        <ProductLevelModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.toggle}
          level={level}
          onClosed={editModalActions.onClosed}
          onSave={(result) => {
            update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(level.id, result)
          }}
        />
      )}
      {createAttributeModalState.value && (
        <ProductLevelAttributeModal
          isOpen={createAttributeModalState.isOpen}
          toggle={createAttributeModalActions.toggle}
          onClosed={createAttributeModalActions.onClosed}
          attribute={{
            ...createAttributeModalState.value,
            product_level: level.id,
            product_level_name: level.product_name + ' ' + level.name,
          }}
          onSave={(result) => {
            const fn = result.id ? updateAttribute.curry(result.id) : createAttribute
            return fn
              .onSuccess(() => {
                createAttributeModalActions.close()
              })
              .asPromise(omit(result, 'product_level_name'))
          }}
        />
      )}
      {createOptionModalState.value && (
        <ProductLevelOptionModal
          isOpen={createOptionModalState.isOpen}
          toggle={createOptionModalActions.toggle}
          onClosed={createOptionModalActions.onClosed}
          option={{
            ...createOptionModalState.value,
            product_level: level.id,
            product_level_name: level.product_name + ' ' + level.name,
          }}
          legacyMode={level.legacy_mode}
          onSave={(result) => {
            const fn = result.id ? updateOption.curry(result.id) : createOption
            return fn
              .onSuccess(() => {
                createOptionModalActions.close()
              })
              .asPromise(omit(result, 'product_level_name'))
          }}
        />
      )}
    </Layout>
  )
}

import dayjs from 'dayjs'
import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { Alt, ArrowLeft, CheckCircle, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { InputField, SelectField, TextAreaField, transformErrorForForm } from '../Form'
import { OrganizationTypeaheadField } from '../OrganizationTypeahead'
import { UserTypeaheadField } from '../UserTypeahead'

const KEY_TYPE_OPTIONS = [
  { value: 'sentinel-hl', label: 'sentinel-hl' },
  { value: 'sentinel-hl-net', label: 'sentinel-hl-net' },
  { value: 'sentinel-sl', label: 'sentinel-sl' },
  { value: 'sentinel-sl-net', label: 'sentinel-sl-net' },
  { value: 'sentinel-sl-vm', label: 'sentinel-sl-vm' },
  { value: 'sentinel-sl-trial', label: 'sentinel-sl-trial' },
  { value: 'smartkey', label: 'smartkey' },
  { value: 'smartkey-net', label: 'smartkey-net' },
  { value: 'sentinel-cl', label: 'sentinel-cl' },
  { value: 'AutoNester', label: 'AutoNester' },
  { value: 'Software', label: 'Software' },
  { value: 'Parallela', label: 'Parallela' },
  { value: 'NestLib', label: 'NestLib' },
  { value: 'Hasp', label: '[DEPRECATED] Hasp' },
  { value: 'Hasp Rete', label: '[DEPRECATED] Hasp Rete' },
  { value: 'Hasp Software', label: '[DEPRECATED] Hasp Software' },
  { value: 'Trial Software', label: '[DEPRECATED] Trial Software' },
  { value: 'Eutron', label: '[DEPRECATED] Eutron' },
  { value: 'Eutron Rete', label: '[DEPRECATED] Eutron Rete' },
]

const INITIAL_VALUES = {
  number: '',
  customer: null,
  oem: null,
  activation_date: '',
  serial_number: '',
  notes: '',
  type: KEY_TYPE_OPTIONS[0].value,
  referrer: null,
}

function transformInputValues(values) {
  const result = { ...values }
  if (result.activation_date) {
    result.activation_date = dayjs(result.activation_date).format('YYYY-MM-DDTHH:mm')
  }
  return result
}

function transformOutputValues(values) {
  const result = { ...values }
  if (result.activation_date) {
    result.activation_date = dayjs(result.activation_date).format('YYYY-MM-DD')
  } else {
    result.activation_date = null
  }
  return result
}

export function LicenseKeyModal({ onSave, isOpen, toggle, onClosed, license_key = INITIAL_VALUES, action = 'edit' }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        number: Yup.string().required(
          t('form_required', {
            field: t('number'),
          })
        ),
        oem: Yup.number().required(
          t('form_required', {
            field: t('oem'),
          })
        ),
        customer: Yup.number().required(
          t('form_required', {
            field: t('customer'),
          })
        ),
        activation_date: Yup.string().required(
          t('form_required', {
            field: t('activation_date'),
          })
        ),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={transformInputValues(license_key)}
        onSubmit={(values, actions) => {
          return onSave(transformOutputValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Alt className="mr-2" />
                  {t(action + '_key')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field required label={t('number')} name="number" component={InputField} disabled={!!license_key.id} />
                <Field required name="customer" label={t('customer')} component={OrganizationTypeaheadField} />
                <Field required name="oem" label={t('oem')} component={OrganizationTypeaheadField} />
                <Field
                  label={t('activation_date')}
                  name="activation_date"
                  component={InputField}
                  type="date"
                  required
                />
                <Field label={t('serial_number')} name="serial_number" component={InputField} />
                <Field required label={t('type')} name="type" component={SelectField} options={KEY_TYPE_OPTIONS} />
                <Field label={t('notes')} name="notes" component={TextAreaField} />
                <Field label={t('attached_user')} name="referrer" component={UserTypeaheadField} />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" disabled={isSubmitting} type="button">
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button icon={<PlusCircleDotted size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button icon={<CheckCircle size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

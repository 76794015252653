import * as dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import parse from 'html-react-parser'
import { identity, omit } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Badge, Spinner, Table } from 'react-bootstrap'
import {
  ArrowClockwise,
  BoxSeam,
  Check2,
  Download,
  Eye,
  Key,
  Pencil,
  ShieldLockFill,
  ShieldPlus,
  Upload,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { deps } from 'react-rocketjump'
import { Link, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import { LicenseKeyModal } from '../../components/LicenseKeyModal'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import { useDlicCompute, useDlicPreview } from '../../hooks/useDlic'
import { useHwKeyDlics } from '../../hooks/useHwKeyExtra'
import { useLicenseKey } from '../../hooks/useLicenseKeys'
import { useLicenses } from '../../hooks/useLicenses'
import { useLicensesUvi } from '../../state/license-uvicorn'
import LicensesListOrgUvi from '../../components/LicensesListOrgUvi'

function downloadDlicFileLegacy(licensesUvi) {
  let dlic = '[General]\n'
  dlic += licensesUvi
    .map((license) => {
      if (license.level) {
        return `${license.productId}=${license.keyCode}\n${license.productId}_level=${license.level}\n`
      } else {
        return `${license.productId}=${license.keyCode}\n`
      }
    })
    .join('')
  const dlicBlob = new Blob([dlic], { type: 'text/plain' })
  saveAs(dlicBlob, 'dlic_' + licensesUvi[0].keyNumber + '_v' + licensesUvi[0].version)
}

function useLoadedLicenses(keyId) {
  const filters = useMemo(() => {
    if (!keyId) {
      return null
    }
    return {
      hardware_key: keyId,
    }
  }, [keyId])

  return useLicenses(deps.maybe(filters))
}

function useLoadedLicensesUvi(keyId) {
  const filters = useMemo(() => {
    if (!keyId) {
      return null
    }
    return {
      keyNumber: keyId,
    }
  }, [keyId])

  return useLicensesUvi(deps.maybe(filters))
}

export default function HwKeyDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [{ key, error }, { uploadC2V, update, run: reloadKey, getC2V }] = useLicenseKey(id)
  const [, dlicActions] = useDlicPreview()
  const [, { run: computeDlic }] = useDlicCompute()
  const [{ data: dlics }, { run: reloadDlics, getContent, getV2C, getErrorLog }] = useHwKeyDlics(
    deps.maybeGet(key, 'id')
  )
  const [{ licenses, error: licensesError }] = useLoadedLicenses(key?.id)

  const [{ licensesUvi, loading }] = useLoadedLicensesUvi(key?.number)

  const c2vRef = useRef()
  const [uploadingPhase, setUploadingPhase] = useState(0)

  const [editModalState, editModalActions] = useModalTrigger()

  const downloadDlicFile = useCallback(() => {
    dlicActions.run
      .onSuccess((dlicContent) => {
        const dlicJson = JSON.stringify(dlicContent)
        const dlicBlob = new Blob([dlicJson], { type: 'application/json' })
        saveAs(dlicBlob, 'dlic_' + id + '_v' + dlicContent.version + '.dlic')
      })
      .run({ hardware_key: id })
  }, [dlicActions, id])

  const handleUploadC2V = useCallback(
    (e) => {
      const file = e.target.files[0]
      setUploadingPhase(1)
      uploadC2V
        .onSuccess(() => {
          setUploadingPhase(2)
          setTimeout(() => setUploadingPhase(0), 5000)
        })
        .run(id, file)
    },
    [id, uploadC2V]
  )

  if (error || licensesError) {
    return <PageError error={error || licensesError} />
  }

  if (key === null || loading) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <h2 className="m-0 d-flex align-items-center">
              <Key className="mr-2" size={31} /> {key.number} - {key.customer_name}
            </h2>
            {key.needs_dlic && (
              <Badge variant="warning" className="rounded ml-4">
                {t('needs_dlic_generation')}
              </Badge>
            )}
          </div>
          <div className="text-right">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => editModalActions.open(key)}
              icon={<Pencil size={15} />}
              className="mr-2"
            >
              {t('edit_key')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Detail className="col-md-8">
            <Detail.Row label={t('number')} value={key.number} />
            <Detail.Row label={t('customer')} value={<Link to={`/orgs/${key.customer}`}>{key.customer_name}</Link>} />
            <Detail.Row label={t('activation_date')} value={dayjs(key.activation_date).format('DD/MM/YYYY')} />
            <Detail.Row label={t('oem')} value={<Link to={`/orgs/${key.oem}`}>{key.oem_name}</Link>} />
            <Detail.Row label={t('type')} value={key.type} />
            <Detail.Row label={t('notes')} value={key.notes ? parse(key.notes) : ''} />
            <Detail.Row
              label={t('attached_user')}
              value={<span>{[key?.referrer_full_name, key?.referrer_email].filter(identity).join(' - ')}</span>}
            />
            <Detail.Row
              label={t('activation_token')}
              value={
                <span>
                  <code>{key.activation_token}</code>
                  <Button
                    variant="outline-primary"
                    className="ml-2"
                    icon={<ArrowClockwise size={14} />}
                    onClick={() => {
                      update(key.number, { activation_token: '' })
                    }}
                  >
                    {t('regenerate_token')}
                  </Button>
                </span>
              }
            />
            <Detail.Row
              label={t('C2V')}
              value={
                <span>
                  <Button
                    variant="outline-primary"
                    className="mr-2"
                    disabled={!key?.last_c2v}
                    icon={<Download size={14} />}
                    onClick={() => {
                      getC2V
                        .onSuccess((blob) => {
                          saveAs(blob, key.number + '.c2v')
                        })
                        .run(id)
                    }}
                  >
                    {t('download')}
                  </Button>
                  <Button
                    variant="outline-primary"
                    icon={
                      <span>
                        {uploadingPhase === 0 && <Upload size={14} />}
                        {uploadingPhase === 1 && <Spinner size="sm" />}
                        {uploadingPhase === 2 && <Check2 size={14} />}
                      </span>
                    }
                    onClick={() => {
                      c2vRef.current.click()
                    }}
                  >
                    {t('upload')}
                  </Button>
                  <input type="file" ref={c2vRef} style={{ display: 'none' }} onChange={handleUploadC2V} />
                </span>
              }
            />
          </Detail>
        </div>
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end mt-5">
          <h3 className="m-0 d-flex align-items-center">
            <BoxSeam className="mr-2" size={31} /> {t('licenses_present_on_key')} {key.number}
          </h3>
        </div>
        <div className="row mt-3 px-3">
          <Table responsive hover>
            <thead>
              <tr>
                <th>{t('id')}</th>
                <th>{t('product')}</th>
                <th>{t('concurrency')}</th>
                <th>{t('dlic_license_expire_date')}</th>
              </tr>
            </thead>
            <tbody>
              {licenses &&
                licenses.map((license) => (
                  <tr key={license.id}>
                    <td>
                      <Link to={`/licenseconfig/${license.id}`}>{license.id}</Link>
                    </td>
                    <td>{license.commercial_name}</td>
                    <td>{license.concurrency}</td>
                    <td>
                      {license.license_type === 'perpetual' && <span>{t('dlic_license_perpetual')}</span>}
                      {license.license_type === 'expire' && (
                        <span>{dayjs(license.expire_date).format('DD/MM/YYYY')}</span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end mt-5">
          <h3 className="m-0 d-flex align-items-center">
            <BoxSeam className="mr-2" size={31} /> {t('licenses_legacy_present_on_key')} {key.number}

          </h3>
          <div>
            <Button
              variant="outline-primary"
              className="mr-2"
              disabled={licenses?.length > 0 && licensesUvi?.length === 0}
              icon={<Eye size={18} />}
              onClick={() => {
                downloadDlicFileLegacy(licensesUvi)
              }}
            >
              {t('download_dlic_legacy')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <LicensesListOrgUvi licenses={licensesUvi} />
        </div>
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end mt-5">
          <h3 className="m-0 d-flex align-items-center">
            <ShieldLockFill className="mr-2" size={31} /> {t('file_dlic_generated')}
          </h3>
          <div>
            <Button
              variant="outline-primary"
              className="mr-2"
              disabled={licensesUvi?.length > 0 && licenses?.length === 0}
              icon={<Eye size={18} />}
              onClick={() => {
                downloadDlicFile()
              }}
            >
              {t('preview')}
            </Button>
            <Button
              variant="outline-primary"
              icon={<ShieldPlus size={18} />}
              disabled={licensesUvi?.length > 0 && licenses?.length === 0}
              onClick={() => {
                computeDlic
                  .onSuccess(() => {
                    reloadDlics(key.id)
                    reloadKey(id)
                  })
                  .run({ hardware_key: id })
              }}
            >
              {t('store_dlic')}
            </Button>
          </div>
        </div>
        <div className="row mt-3 px-3">
          <Table responsive hover>
            <thead>
              <tr>
                <th>{t('version')}</th>
                <th>{t('dlic_created_at')}</th>
                <th>{t('dlic_applied_at')}</th>
                <th>{t('dlic_downloaded_at')}</th>
                <th>{t('dlic_status')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {dlics &&
                dlics.map((dlic) => (
                  <tr key={dlic.id}>
                    <td>{dlic.version}</td>
                    <td>{dayjs(dlic.created_at).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{dlic.applied_at && dayjs(dlic.applied_at).format('DD/MM/YYYY HH:mm')}</td>
                    <td>{dlic.last_downloaded_at && dayjs(dlic.last_downloaded_at).format('DD/MM/YYYY HH:mm')}</td>
                    <td>
                      {!dlic.v2c && !dlic.error_log && <span>{t('dlic_status_pending')}</span>}
                      {!!dlic.v2c && <span>{t('dlic_status_signed')}</span>}
                      {!dlic.v2c && !!dlic.error_log && (
                        <div>
                          <span>{t('dlic_status_error')}</span>
                          <br />
                          <small
                            className="pointer"
                            onClick={() => {
                              getErrorLog
                                .onSuccess((blob) => {
                                  saveAs(blob, 'log_' + id + '_v' + dlic.version + '.txt')
                                })
                                .run(dlic.id)
                            }}
                          >
                            <u>{t('download_log')}</u>
                          </small>
                        </div>
                      )}
                    </td>
                    <td className="text-right">
                      <Button
                        variant="outline-primary"
                        className="mr-2"
                        disabled={licensesUvi?.length > 0 && licenses?.length === 0}
                        icon={<Download size={18} />}
                        onClick={() => {
                          getContent
                            .onSuccess((result) => {
                              const dlicJson = JSON.stringify(result).replace(/\n/g, '\r\n')
                              const dlicBlob = new Blob([dlicJson], {
                                type: 'application/json',
                              })
                              saveAs(dlicBlob, 'dlic_' + id + '_v' + dlic.version + '.dlic')
                            })
                            .run(dlic.id)
                        }}
                      >
                        {t('download_content')}
                      </Button>
                      <Button
                        variant="outline-primary"
                        icon={<Download size={18} />}
                        disabled={!dlic.v2c || (licensesUvi?.length > 0 && licenses?.length === 0)}
                        onClick={() => {
                          getV2C
                            .onSuccess((blob) => {
                              saveAs(blob, 'v2c_' + id + '_v' + dlic.version + '.v2c')
                            })
                            .run(dlic.id)
                        }}
                      >
                        {t('download_v2c')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      {editModalState.value && (
        <LicenseKeyModal
          isOpen={editModalState.isOpen}
          toggle={editModalActions.close}
          onClosed={editModalActions.onClosed}
          license_key={editModalState.value}
          onSave={(result) => {
            return update
              .onSuccess(() => {
                editModalActions.close()
              })
              .asPromise(id, omit(result, 'last_c2v'))
          }}
        />
      )}
    </Layout>
  )
}
